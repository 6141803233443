import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import Header from '../../components/Header';
import Footer from '../../components/Footer/Footer';

import { InView } from 'react-intersection-observer';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Tabs from '../Main/components/Tabs/Tabs';
import ResultsTable from '../Main/components/ResultsTable/ResultsTable';

//Assets
import MagicBox from '../../../asset/resource/MagicBox.png';
import video from '../../../asset/resource/video.webm';
import safari from '../../../asset/resource/safari.mov';
import softserve_logo from '../../../asset/resource/softserve_logo.png';
import xr_image from '../../../asset/resource/xr_image.png';
import iot_image from '../../../asset/resource/iot_image.png';
import web_portal_image from '../../../asset/resource/web_portal_image.png';
import servicenow_image from '../../../asset/resource/servicenow_image.png';
import inspect_image from '../../../asset/resource/inspect_image.png';
import ticket_modals from '../../../asset/resource/ticket_modals.png';
import remote_support from '../../../asset/resource/remote_support.png';
import manufacturing from '../../../asset/resource/manufacturing.png';
import healthcare from '../../../asset/resource/healthcare.png';
import supply_chain from '../../../asset/resource/supply_chain.png';
import networks from '../../../asset/resource/networks.png';
import qr_code_register from '../../../asset/resource/qr_code_register.png';

const navLinks = [
  {
    id: 1,
    title: 'How it works',
    anchor: 'how-it-work',
  },
  {
    id: 2,
    title: 'How to use',
    anchor: 'how-to-use',
  },
  {
    id: 3,
    title: 'Use cases',
    anchor: 'use-cases',
  },
  {
    id: 4,
    title: 'Challenge',
    anchor: 'challenge',
  },
];

import './Touchscreen.scss';

function Touchscreen() {
  const [blockView, setBlockView] = useState([]);
  const [imageView, setImageView] = useState([]);
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);

  const handleView = (inView) => {
    if (inView) {
      var video = document.getElementById('box-video');
      setTimeout(function () {
        video.play();
      }, 2000);
    }
  };

  const handleViewBlocks = (inView, i) => {
    if (inView) {
      setBlockView((prevArray) => [...prevArray, (blockView[i] = true)]);
    }
  };

  const handleViewImages = (inView, i) => {
    if (inView) {
      setImageView((prevArray) => [...prevArray, (imageView[i] = true)]);
    }
  };

  useEffect(() => {
    let isSafari = window.navigator.vendor.toLowerCase().indexOf('apple') > -1;
    if (isSafari) setIsSafariBrowser(true);
  }, [isSafariBrowser]);

  return (
    <div className="main touchscreen" id="home">
      <Header logo={MagicBox} links={navLinks} />

      <div className="main__outter-bg">
        <div className="main__inner-bg">
          <section className="main__heading">
            <div className="main__heading-title">
              <h1>
                What’s <br />
                inside the
                <br />
                <span>magic box?</span>
              </h1>
              <p className="main__heading-description">
                Magic Box is a SoftServe-created solution that helps engineers and field technicians perform service
                management and equipment maintenance using extended reality, leading-edge digital twin technology, IoT,
                and advanced UX design.
              </p>
              <p>
                A user can find and fix physical malfunctions faster and more efficiently using visual prompts and
                instructions.
              </p>
              <p>
                It also integrates with other advanced technology approaches to cover the complete cycle of equipment
                maintenance—from inspection to inventory to resolving malfunctions.
              </p>
            </div>
            <InView onChange={handleView} threshold={1} className="main__inview">
              <video id="box-video" className="main__video-content" width="100%" loop muted playsInline>
                {isSafariBrowser ? <source src={safari} type="video/mp4" /> : <source src={video} type="video/webm" />}
                Your browser is not supported!
              </video>
            </InView>
          </section>
        </div>
      </div>

      <div className="main__wrapper">
        <section id="how-it-work" className="main__how-it-works">
          <h2>
            Explore the technology <br /> behind magic box
          </h2>
          <div className="main__how-it-works-blocks">
            <InView triggerOnce onChange={(inView) => handleViewBlocks(inView, 0)} threshold={1}>
              <div
                className={cn(
                  'main__how-it-works-block',
                  blockView[0] && 'main__how-it-works-block-done',
                  'main__how-it-works-block_one',
                )}
              >
                <figure>
                  <img width="100%" src={xr_image} className="xr-logo" alt="logo" />
                </figure>
                <figcaption>
                  Magic Box uses <span>Magic Leap</span> — an augmented reality headset that brings digital objects into
                  the real world. <br />
                  <br />
                  <span>Digital twin</span> technology delivers a virtual model designed to accurately reflect a
                  physical object, run simulations, study performance issues, and generate improvements. <br />
                  <br />
                  The <span>hand tracking</span> system identifies key points on the user's hands, allowing them to
                  interact in mixed reality without a controller.
                </figcaption>
              </div>
            </InView>
            <InView triggerOnce onChange={(inView) => handleViewBlocks(inView, 1)} threshold={1}>
              <div
                className={cn(
                  'main__how-it-works-block',
                  blockView[1] && 'main__how-it-works-block-done',
                  'main__how-it-works-block_two',
                )}
              >
                <figure>
                  <img width="100%" src={iot_image} className="xr-logo" alt="logo" />
                </figure>
                <figcaption>
                  Magic Box use <span>multiple sensors</span> to provide accurate information about the equipment itself
                  or the ambient conditions around it on items such as temperature, pressure, light, and position.
                  <br />
                  <br />
                  In nearly real-time, the <span>telemetry</span> system transmits this data from remote sources into
                  Magic Box for monitoring and analysis.
                  <br />
                  <br />
                  From there, <span>Google Cloud services</span> quickly and securely connects, manages, and ingests
                  this data.{' '}
                </figcaption>
              </div>
            </InView>
            <InView triggerOnce onChange={(inView) => handleViewBlocks(inView, 2)} threshold={1}>
              <div
                className={cn(
                  'main__how-it-works-block',
                  blockView[2] && 'main__how-it-works-block-done',
                  'main__how-it-works-block_three',
                )}
              >
                <figure>
                  <img width="100%" src={web_portal_image} className="xr-logo" alt="logo" />
                </figure>
                <figcaption>
                  The <span>Web Portal</span> collects all of the information from the various sources which make up the
                  Magic Box system. This includes the Magic Leap glasses, IoT sensors, ServiceNow tickets, and more.
                  Users can then interact with the interface that allows them to <span>manage</span> equipment, roles,
                  personnel, tutorials, and checklists.
                  <br />
                  <br />
                  Using the <span>remote assistance</span> functionality, off-site and physically remote users are
                  notified of issues. With one user wearing the XR glasses and the other using Web Portal, they can work
                  on and fix these issues together.{' '}
                </figcaption>
              </div>
            </InView>
            <InView triggerOnce onChange={(inView) => handleViewBlocks(inView, 3)} threshold={1}>
              <div
                className={cn(
                  'main__how-it-works-block',
                  blockView[3] && 'main__how-it-works-block-done',
                  'main__how-it-works-block_four',
                )}
              >
                <figure>
                  <img width="100%" src={servicenow_image} className="xr-logo" alt="logo" />
                </figure>
                <figcaption>
                  Integration with the <span>ServiceNow</span> tool provides workflows designed for centralized IT
                  service management. It enables Magic Box users to streamline and automate routine work tasks.
                  <br />
                  <br />
                  Access is available on both the <span>web version</span> and the <span>XR app</span>.
                </figcaption>
              </div>
            </InView>
          </div>
        </section>

        <section id="how-to-use" className="main__how-to-use">
          <h2>How you can use Magic Box</h2>
          <div className="main__how-to-use-blocks">
            <div className="main__how-to-use-block one">
              <article>
                <h3>Inspect</h3>
                <p>
                  With digital twin, users can assemble and disassemble the equipment, providing full visibility into
                  the object and its parts.
                  <br />
                  <br />
                  Overlayed holograms give users access to the necessary pieces for assessment so they can gain insight
                  into the object’s physical operations before finding any causes of failure. Solutions to fix the
                  issues can then be applied to the original physical object.
                </p>
              </article>
              <InView triggerOnce onChange={(inView) => handleViewImages(inView, 0)} threshold={1}>
                <figure className={cn('main__how-to-use-image', imageView[0] && 'main__how-to-use-image-done')}>
                  <img width="100%" src={inspect_image} className="inspect_image" alt="inspect_image" />
                </figure>
              </InView>
            </div>

            <div className="main__how-to-use-block two">
              <article>
                <h3>Manage</h3>
                <p>
                  Users can create, edit, and resolve tickets in both Web and XR apps.
                  <br />
                  <br />
                  Additional options let users add manuals and videos to checklist steps to make instructions as clear
                  as possible.
                  <br />
                  <br />
                  An easy-to-use interface helps users to manage equipment inventory such as IoT sensors and other Magic
                  Boxes.
                </p>
              </article>
              <InView triggerOnce onChange={(inView) => handleViewImages(inView, 1)} threshold={1}>
                <figure className={cn('main__how-to-use-image', imageView[1] && 'main__how-to-use-image-done')}>
                  <img width="100%" src={ticket_modals} className="ticket_modals" alt="ticket_modals" />
                </figure>
              </InView>
            </div>

            <div className="main__how-to-use-block three">
              <article>
                <h3>Fix</h3>
                <p>
                  Step-by-step troubleshooting detects the problem and fixes it using visual prompts.
                  <br />
                  <br />
                  If assistance is needed, remote human support is available.
                  <br />
                  <br />
                  The See-what-I-see function allows several people from different locations to work on the same issue
                  together. One engineer broadcasts the view from their glasses, allowing off-site but experienced
                  experts to study the situation and diagnose potential causes.
                </p>
              </article>
              <InView triggerOnce onChange={(inView) => handleViewImages(inView, 2)} threshold={1}>
                <figure className={cn('main__how-to-use-image', imageView[2] && 'main__how-to-use-image-done')}>
                  <img width="100%" src={remote_support} className="remote_support" alt="remote_support" />
                </figure>
              </InView>
            </div>
          </div>
        </section>
      </div>

      <section id="use-cases" className="main__use-cases">
        <h2>Magic Box Use Cases</h2>
        <div className="main__use-cases-slider-wrapper">
          <Carousel showArrows infiniteLoop showThumbs={false} showStatus={false}>
            <div className="main__use-cases-slide">
              <article>
                <h3>Manufacturing</h3>
                <p>
                  <span>
                    The Magic Box system covers the full spectrum of the maintenance cycle. This enables the ability to
                    work remotely with digital versions of real objects.
                  </span>
                  <br />
                  <br />
                  Magic Box collects real-time data, which lets users remotely operate equipment and predict and detect
                  issues.
                  <br />
                  <br />
                  The XR application makes it easier for people to work with the equipment itself and proceed with
                  improvements or design changes.
                </p>
                <br />
                <br />
                <p>Manufacturing use cases include:</p>
                <ul>
                  <li>Maintenance</li>
                  <li>Design customization</li>
                  <li>Product development</li>
                </ul>
              </article>
              <figure className="main__how-to-use-image">
                <img width="100%" src={manufacturing} className="manufacturing" alt="manufacturing" />
              </figure>
            </div>
            <div className="main__use-cases-slide">
              <article>
                <h3>Healthcare</h3>
                <p>
                  <span>
                    Magic Box enables the next step in precision medicine and preventive care with the digital
                    representation of healthcare data.
                  </span>
                  <br />
                  <br />
                  Digital twin technology can be used to generate a virtual twin of a hospital to optimize resources or
                  manage risks. Applying this technology to patients' data can also improve medical care and patient
                  treatment.
                </p>
                <br />
                <br />
                <p>Healthcare use cases include:</p>
                <ul>
                  <li>Personalized diagnosis using data</li>
                  <li>Treatment planning</li>
                  <li>Training</li>
                </ul>
              </article>
              <figure className="main__how-to-use-image">
                <img width="100%" src={healthcare} className="healthcare" alt="healthcare" />
              </figure>
            </div>
            <div className="main__use-cases-slide">
              <article>
                <h3>Supply chain</h3>
                <p>
                  <span>
                    Magic Box can provide a detailed simulation model of an actual supply chain by using real-time data
                    and snapshots to forecast supply chain dynamics
                  </span>
                  <br />
                  <br />
                  Users can analyze risks, KPIs, demand, inventory levels, suppliers, sales data, and more, and see the
                  optimal course of action under a variety of circumstances.
                </p>
                <br />
                <br />
                <p>Supply Chain use cases include:</p>
                <ul>
                  <li>Packaging verification</li>
                  <li>Warehouse optimization</li>
                </ul>
              </article>
              <figure className="main__how-to-use-image">
                <img width="100%" src={supply_chain} className="supply_chain" alt="supply_chain" />
              </figure>
            </div>
            <div className="main__use-cases-slide">
              <article>
                <h3>Networks</h3>
                <p>
                  <span>
                    Magic Box provides easy-to-understand, real-time visualization of the complex system connecting
                    computer hardware with other IT equipment.
                  </span>
                  <br />
                  <br />
                  Users can more quickly identify issues, enhance environmental control, and improve cable management
                  using tools such as asset visualization, context data, and notifications for physical equipment via
                  the VR application. Wearing a headset allows workers to be hands-free while solving issues, increasing
                  safety, improving productivity, and reducing downtime for asset detection.
                </p>
                <br />
                <br />
                <p>Network use cases include:</p>
                <ul>
                  <li>Server room maintenance</li>
                  <li>Network supervision</li>
                </ul>
              </article>
              <figure className="main__how-to-use-image">
                <img width="100%" src={networks} className="networks" alt="networks" />
              </figure>
            </div>
          </Carousel>
        </div>
      </section>

      <div className="main__wrapper">
        <section id="challenge" className="main__challenge touchscreen__chanllenge">
          <h2>Take The Challenge</h2>
          <article>
            <p>
              <span>
                Do you want to experience the magic yourself?
                <br /> Put on the glasses and test your skills for a chance to win <br />
                an Oculus Quest VR set!
              </span>
              <br />
              <br />
              There's an emergency at an electric supply plant, and the entire district will be stuck in the dark if the
              issue isn't fixed immediately.
              <br />
              <br />
              You are the only person with access to the room with the potential malfunction.
              <br />
              <br />
              Dare to dive in and save the district's power source from failure?
              <br />
              <br />
              <span className="big-text">
                All participants will be entered for the chance to win an Oculus Quest VR set. <br />
                The winner will be selected randomly on
                <span> Thursday, 20 April at 4 p.m.</span>
              </span>
              <br />
              <br />
              <span className="small-text">
                <u id="note">Note:</u> The demo will be live throughout Hannover Messe 2023 from 17 – 21 April.
                Participants are limited to one entry. The winner will be contacted by email. The prize can be picked up
                until 3 p.m. on 21 April at our booth.
              </span>
            </p>
            <figure className="touchscreen__chanllenge-qr-image">
              <img width="100%" src={qr_code_register} className="qr-code" alt="qr-code" />
              <figcaption>Scan me</figcaption>
            </figure>
          </article>
          <div className="empty-placeholder">
            <h3>Cisco Live</h3>
          </div>
          <div className="main__challenge-results touchscreen__challenge-results">
            <Tabs titles={[{ name: 'Leader board' }]} items={[<ResultsTable />]} />
          </div>
        </section>
      </div>

      <Footer logo={softserve_logo} />
    </div>
  );
}

export default Touchscreen;
