import React, { useState } from 'react';
import { get, isEmpty } from 'lodash';
import cn from 'classnames';

import SuccessMessage from '../SuccessMessage/SuccessMessage';
import { Form, Field } from 'components/Form';
import validation from 'components/Form/validation.js';
import { postForm } from '../../../../config';

export const RegistrationGameForm = ({ statusHandler }) => {
  const [values, setValues] = useState({
    data: {},
    errors: {},
    status: '',
    userGuid: '',
  });

  const validate = () => {
    const errors = validation(values.data);

    if (!isEmpty(errors)) {
      setValues((prevState) => ({
        ...prevState,
        errors: {
          ...errors,
        },
      }));
    }

    return isEmpty(errors);
  };

  const onChangeField = (event) => {
    const { name, type, value, checked } = event.target;
    const fieldValue = type === 'checkbox' || type === 'radio' ? checked : value;

    setValues((prevState) => ({
      ...prevState,
      data: {
        ...values.data,
        [name]: fieldValue,
      },
    }));
  };

  const submitForm = (event) => {
    event.preventDefault();

    const formValues = {
      firstName: values.data.firstname,
      lastName: values.data.lastname,
      email: values.data.email,
      nickname: values.data.nickname,
    };

    if (validate()) {
      postForm(formValues)
        .then((data) => {
          setValues({
            data: {},
            errors: {},
            status: '',
          });

          if (data.isSuccess) {
            setValues((prevState) => ({
              ...prevState,
              status: {
                ...values.status,
                status: 'success',
              },
            }));

            setValues((prevState) => ({
              ...prevState,
              userGuid: data.object,
            }));
          }
        })
        .catch(function (error) {
          console.log({ name: 'Error:', message: `${DOMPurify.sanitize(error)}` });

          if (!error.response.data.isSuccess) {
            setValues((prevState) => ({
              ...prevState,
              errors: {
                ...values.errors,
                nickname: error.response.data.messsage.includes('nickname') && 'This nickname is already taken.',
                email: error.response.data.messsage.includes('email') && 'Provided email already exists.',
                failedSubmit:
                  (error.response.data.messsage === 'Error occurred on creating user in Firebase.' ||
                    error.response.data.messsage === 'An error occurred') &&
                  'Something is wrong, please contact SoftServe support team.',
              },
            }));

            return;
          }
        });
    }
  };

  return values.status ? (
    <SuccessMessage guid={String(values.userGuid)} />
  ) : (
    <div className="game-form__form-wrap">
      <h1 className="game-form__form-title">Register To Take The Magic Box Challenge</h1>
      <p className="game-form__form-description">
        Complete the form below to take part in the Magic Box challenge. <br />
        Once you submit the form, you’ll be sent a QR code. <br />
        Show the QR code at the SoftServe booth to an associate to get started.
      </p>
      <Form name="registration-game" noValidate>
        <div className="form-box__row">
          <div className="form-box__col">
            <Field
              onChange={onChangeField}
              name="nickname"
              defaultValue={get(values.data, 'nickname', '')}
              label="Username *"
              error={get(values.errors, 'nickname', false)}
              maxLength={100}
              required
            />
            <Field
              onChange={onChangeField}
              name="email"
              defaultValue={get(values.data, 'email', '')}
              label="E-mail *"
              error={get(values.errors, 'email', false)}
              maxLength={100}
              required
            />
            <Field
              onChange={onChangeField}
              name="firstname"
              defaultValue={get(values.data, 'firstname', '')}
              label="First Name"
              error={get(values.errors, 'firstName', false)}
              maxLength={100}
              required
            />
            <Field
              onChange={onChangeField}
              name="lastname"
              defaultValue={get(values.data, 'lastname', '')}
              label="Last Name"
              error={get(values.errors, 'lastName', false)}
              maxLength={100}
              required
            />
          </div>
        </div>
        {get(values.errors, 'failedSubmit', '') && (
          <div className="form-input__error">{values.errors.failedSubmit}</div>
        )}
        <p className="game-form__form-description_bottom">
          Your e-mail address and name will be collected for the purposes of the challenge only. This data will be held
          for the duration of the challenge, and deleted within 14 days following the challenge.
        </p>
        <button
          type="button"
          disabled={!values.data.nickname || !values.data.email}
          onClick={submitForm}
          className={cn('game-form__button', (!values.data.nickname || !values.data.email) && 'disabled')}
        >
          Register
        </button>
      </Form>
    </div>
  );
};

export default RegistrationGameForm;
