import React from 'react';
import { Link as ScrollLink } from 'react-scroll';

import './EventAd.scss';

export const EventAd = () => {
  return (
    <div className="event-ad">
      <h3>Add Magic Box to your event!</h3>
      <p>
        Want to share the Magic Box experience and showcase its
        <br /> exciting technology to your team?
      </p>
      <ScrollLink smooth spy className="game-form__button event-ad__button" to={'contact-us'} duration={500}>
        Let's talk
      </ScrollLink>
    </div>
  );
};

export default EventAd;
