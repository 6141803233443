import React, { useState } from 'react';
import cn from 'classnames';

import './Randomizer.scss';

const parserUsers = (users) =>
  users &&
  users.map((user, index) => ({
    id: user.id,
    rank: index + 1,
    nickname: user.nickname,
    duration: user.duration,
  }));

export const Randomizer = ({ participants }) => {
  const resultList = parserUsers(participants);
  const [isCongratsVisible, setVisibilityOfCongrants] = useState(false);
  const [randomUser, setRandomUser] = useState({});

  const handleRandomStart = () => {
    let u;
    const max = resultList.length;
    const min = 1;

    for (let i = 1; i < resultList.length; i++) {
      setTimeout(function timer() {
        u = Math.floor(Math.random() * (max - min)) + min;

        setRandomUser(resultList[u]);
      }, i * 100);
    }

    setTimeout(() => {
      setVisibilityOfCongrants(true);
    }, resultList.length * 110);
  };

  return (
    <div className="main__how-to-use">
      {!randomUser.nickname ? (
        <div className="randomizer__container">
          <button className="game-form__button event-ad__button randomizer__button" onClick={() => handleRandomStart()}>
            Choose a winner!
          </button>
          <p className="randomizer__message-text">
            The winner will be randomly chosen from the list of the participants after the
            <br /> challenge is completed. Good luck!
          </p>
        </div>
      ) : (
        <div className="randomizer__results">
          <p className="randomizer__results-winner">
            <span className="randomizer__results-winner-number">{randomUser.rank}</span>
            <span className="randomizer__results-winner-nickname">{randomUser.nickname}</span>
          </p>
          <p className={cn('randomizer__results-text', isCongratsVisible && 'fade')}>
            Congratulations to the winner of the challenge, who dared to try some magic and
            <br /> saved the day!
          </p>
        </div>
      )}
    </div>
  );
};

export default Randomizer;
