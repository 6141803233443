import React from 'react';
import { Route, Routes } from 'react-router-dom';

import routes from './routes';

import './App.scss';

function App() {
  const routeComponents = routes.map(({ path, component: Component }, index) => (
    <Route path={path} key={index} element={<Component />} />
  ));

  return (
    <div className="app">
      <Routes>{routeComponents}</Routes>
    </div>
  );
}

export default App;
