import React, { useState } from 'react';
import { get, isEmpty } from 'lodash';
import axios from 'axios';

import { Form, Field } from 'components/Form';
import validation from './validation.js';

export const RegistrationForm = () => {
  const [values, setValues] = useState({
    data: {},
    errors: {},
    status: '',
  });

  const validate = () => {
    const errors = validation(values.data);

    if (!isEmpty(errors)) {
      setValues((prevState) => ({
        ...prevState,
        errors: {
          ...errors,
        },
      }));
    }

    return isEmpty(errors);
  };

  const onChangeField = (event) => {
    const { name, type, value, checked } = event.target;
    const fieldValue = type === 'checkbox' || type === 'radio' ? checked : value;

    setValues((prevState) => ({
      ...prevState,
      data: {
        ...values.data,
        [name]: fieldValue,
      },
    }));
  };

  const submitForm = (event) => {
    event.preventDefault();
    const portalId = '2744722';
    const hubspotFormId = '02bb0750-da42-4b17-824a-096505ca81ed';
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${hubspotFormId}`;

    const formValues = {
      fields: Object.entries(values.data).map((item) => ({ name: item[0], value: item[1] })),
      context: {
        pageUri: 'https://magicbox.softserveinc.com',
        pageName: 'Magicbox',
        hutk: 'bc443d84085c8fcf189a9afd50483099',
      },
      skipValidation: true,
    };

    const postForm = (endpoint, params) =>
      axios({
        method: 'POST',
        data: params,
        url: endpoint,
      });

    if (validate()) {
      postForm(url, formValues).then(() => {
        setValues({
          data: {},
          errors: {},
          status: '',
        });

        setValues((prevState) => ({
          ...prevState,
          status: {
            ...values.status,
            status: 'success',
          },
        }));
      });
    }
  };

  return (
    <div className="main__form-wrapper">
      {!values.status && <h2 className="main__form-title">Request a demo</h2>}
      {!values.status && <p className="main__form-description">Submit the form and we will reach out soon!</p>}
      {!values.status ? (
        <Form name="registration" noValidate>
          <div className="form-box__row">
            <div className="form-box__col">
              <Field
                onChange={onChangeField}
                name="firstname"
                defaultValue={get(values.data, 'firstname', '')}
                placeholder="First Name *"
                error={get(values.errors, 'firstName', false)}
                maxLength={100}
                required
              />
            </div>
            <div className="form-box__col">
              <Field
                onChange={onChangeField}
                name="lastname"
                defaultValue={get(values.data, 'lastname', '')}
                placeholder="Last Name *"
                error={get(values.errors, 'lastName', false)}
                maxLength={100}
                required
              />
            </div>
          </div>
          <div className="form-box__row_without-flex">
            <Field
              onChange={onChangeField}
              name="email"
              defaultValue={get(values.data, 'email', '')}
              placeholder="E-mail *"
              error={get(values.errors, 'email', false)}
              maxLength={100}
              required
            />
          </div>
          <div className="form-box__row">
            <div className="form-box__col">
              <Field
                onChange={onChangeField}
                name="company"
                defaultValue={get(values.data, 'company', '')}
                placeholder="Company Name *"
                error={get(values.errors, 'company', false)}
                maxLength={100}
                required
              />
            </div>
            <div className="form-box__col">
              <Field
                onChange={onChangeField}
                name="jobtitle"
                defaultValue={get(values.data, 'jobtitle', '')}
                placeholder="Job title *"
                error={get(values.errors, 'job', false)}
                maxLength={100}
                required
              />
            </div>
          </div>
          <div className="form-box__row_without-flex">
            <Field
              onChange={onChangeField}
              name="message"
              field="textarea"
              value={get(values.data, 'message', '')}
              placeholder="Have something to say? Drop us a message..."
              error={get(values.errors, 'message', false)}
              maxLength={2000}
              required
            />
          </div>
          {get(values.errors, 'fillOutFields', false) && (
            <div className="form-input__error">{values.errors.fillOutFields}</div>
          )}
          <Field
            field="checkbox"
            name="updates_and_offers"
            value={get(values.data, 'updates_and_offers', '')}
            label="I would like to be updated on SoftServe's latest products, event announcements, and thought leadership."
            onChange={onChangeField}
          />
          <p className="form-box__description">
            By clicking “Let's talk”, you agree with the our{' '}
            <a href="https://www.softserveinc.com/en-us/terms-and-conditions">Terms & Conditions</a> and{' '}
            <a href="https://www.softserveinc.com/en-us/privacy-policy">Privacy Policy</a>.
          </p>
          <button type="button" onClick={submitForm} className="main__form-button">
            Let's talk
          </button>
        </Form>
      ) : (
        <div className="main__success">
          <h2 className="main__form-title">THANK YOU FOR SUBMITTING YOUR REQUEST!</h2>
          <p className="main__form-description">
            We have received your request and look forward to sharing what Magic Box can do for your team soon.
          </p>
        </div>
      )}
    </div>
  );
};

export default RegistrationForm;
