import React, { useState, useCallback, useRef } from 'react';
import QRCode from 'react-qr-code';
import { toPng } from 'html-to-image';

import { useCheckMobile } from '../../../../hooks';

import hurray_image from '../../../../../asset/resource/hurray_image.png';

import './SuccessMessage.scss';

export const SuccessMessage = ({ guid }) => {
  const [isHideButton, setIsHideButton] = useState(true);
  const isMobile = useCheckMobile({ width: 360 });
  const ref = useRef(null);

  const downloadQRCode = useCallback(() => {
    if (ref.current === null) {
      setIsHideButton(true);
      return;
    }

    setIsHideButton(false);

    toPng(ref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'game-registration-qr-code.png';
        link.href = dataUrl;
        link.click();
        setIsHideButton(true);
      })
      .catch((err) => {
        console.log({ name: 'Error:', message: `${DOMPurify.sanitize(err)}` });
        setIsHideButton(false);
      });
  }, [ref]);

  return (
    <div className="success-message">
      <figcaption className="success-message__image-wrapper">
        <img src={hurray_image} className="success-message__logo-image" alt="Hurray" />
      </figcaption>
      <h1 className="success-message__form-title">
        Hurray!
        <br />
        You’re ready to take the challenge!
      </h1>
      <div className="success-message__qr-block" id="code" ref={ref}>
        <p className="success-message__qr-description">
          {isHideButton
            ? 'Please save this QR code to participate in the challenge. You can download it or take a screenshot. Show the QR code at the SoftServe booth to an associate to get started.'
            : 'Here is your QR code for the game. Scan it with the XR glasses to start.'}
        </p>
        <div className="success-message__qr">
          <QRCode value={guid} size={isMobile ? 200 : 256} />
        </div>
        {isHideButton && (
          <button type="button" onClick={downloadQRCode} className="game-form__button success-message__button">
            Download QR code
          </button>
        )}
      </div>
    </div>
  );
};

export default SuccessMessage;
