import React, { useEffect, useState } from 'react';
import RegistrationGameForm from './components/RegistrationGameForm/RegistrationGameForm';

import RegisterScreen from '../../../asset/resource/RegisterScreen.png';

import './GameForm.scss';

export const GameForm = () => {
  const [isHide, setIsHide] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsHide(false);
    }, 4000);
  }, []);

  return (
    <div className="game-form">
      {isHide && (
        <div className="game-form__logo-wrapper">
          <figcaption className="game-form__image-wrapper">
            <img src={RegisterScreen} className="game-form__logo-image" alt="MagicBox and Softserve" />
          </figcaption>
        </div>
      )}
      {!isHide && (
        <div className="game-form__form-wrapper">
          <RegistrationGameForm />
        </div>
      )}
    </div>
  );
};

export default GameForm;
