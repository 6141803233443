import { isEmpty } from 'lodash';

export default (values) => {
  let errors = {};

  if (isEmpty(values.nickname)) {
    errors.nickname = true;
  }

  if (isEmpty(values.email)) {
    errors.email = true;
  }

  if (
    !isEmpty(values.email) &&
    !/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
      values.email,
    )
  ) {
    errors.email = 'Please provide correct email.';
  }

  if (!isEmpty(values.nickname) && !/^[\w-_.]*$/.test(values.nickname)) {
    errors.nickname = 'Invalid nickname. You can use letters, numbers, and periods.';
  }

  return errors;
};
