import React, { Fragment, useState } from 'react';
import cn from 'classnames';

import './Tabs.scss';

export const Tabs = ({ titles, items }) => {
  const [selectedItemId, setSelectedItemId] = useState(0);

  const handleSelectedItemIdUpdate = (i) => {
    setSelectedItemId(i);
  };

  return (
    <div className="tabs__container">
      <ul className="tabs__headline">
        {titles.map(({ name }, i) => (
          <h4
            className={cn('tabs__tab', selectedItemId === i && 'selected')}
            key={i}
            onClick={() => handleSelectedItemIdUpdate(i)}
          >
            {name}
          </h4>
        ))}
      </ul>
      <ul className="tabs__content">
        {items.map((item, i) => (
          <Fragment key={i}>
            <div className={cn('tabs__content-item', selectedItemId === i && 'selected')}>{item}</div>
          </Fragment>
        ))}
      </ul>
    </div>
  );
};

export default Tabs;
