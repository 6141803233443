import DOMPurify from 'isomorphic-dompurify';

export function sanitizeHtml(html) {
  let cleanedData = DOMPurify.sanitize(html);
  return cleanedData;
}

export function millisToMinutesAndSeconds(millis) {
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
}

export function showingRandomizerPressDeleteKey(cb) {
  let startTime;
  let delete_down = false;

  document.body.onkeydown = function (event) {
    const key = event.key;
    console.log('start key');
    if (key === 'Delete') {
      event.preventDefault();
      if (delete_down) {
        return;
      }
      delete_down = true;
      startTime = +new Date();
    }
  };

  document.body.onkeyup = function (event) {
    const key = event.key;

    if (key === 'Delete') {
      delete_down = false;
      let endTime = +new Date();
      let time = (endTime - startTime) / 1000;
      console.log(time);
      if (time > 3) {
        cb(true);
      }
    }
  };
}

export function showingRandomizerPressTouchscreen(cb) {
  let startTime;
  let delete_down = false;

  function touchstart(e) {
    e.preventDefault();
    console.log('start touch');
    if (delete_down) {
      return;
    }
    delete_down = true;
    startTime = +new Date();
  }

  function touchend() {
    delete_down = false;
    let endTime = +new Date();
    let time = (endTime - startTime) / 1000;
    console.log(time);
    if (time > 3) {
      cb(true);
    }
  }

  document.addEventListener('DOMContentLoaded', function (event) {
    window.addEventListener('touchstart', touchstart, false);
    window.addEventListener('touchend', touchend, false);
  });
}
