import React from 'react';

import './Footer.scss';

export const Footer = ({ logo }) => {
  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <a
          className="footer__url"
          href="https://softserveinc.com"
          target="_blank"
          rel="noopener noreferrer"
          role="link"
        >
          <img src={logo} className="footer__logo" alt="logo" />
        </a>
        <span className="footer__copyright">© Copyright 2023 SoftServe Inc.</span>
      </div>
    </footer>
  );
};

export default Footer;
