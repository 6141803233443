import React from 'react';
import cn from 'classnames';
import classNames from 'classnames';

import './Sorter.scss';

export const Sorter = ({ handler, active }) => {
  return (
    <div className="sorter">
      <div className="sorter__arrow-wrapper">
        <span
          className={classNames('sorter__up-arrow', active === 'up' && 'sorter__up-arrow_hide')}
          onClick={() => handler('up')}
        >
          &#9698;
        </span>
      </div>
      <div className="sorter__arrow-wrapper">
        <span
          className={cn('sorter__down-arrow', active === 'down' && 'sorter__up-arrow_hide')}
          onClick={() => handler('down')}
        >
          &#9698;
        </span>
      </div>
    </div>
  );
};

export default Sorter;
