import axios from 'axios';

const apiInstance = axios.create({
  baseURL: process.env.API,
});

const usersListConfig = () => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export function getListOfUsers() {
  return apiInstance.get('/api/users/challenge-results', usersListConfig()).then((response) => {
    return response.data;
  });
}

export function postForm(data) {
  return apiInstance.post('/api/users/create-user', data, usersListConfig()).then((response) => {
    return response.data;
  });
}
