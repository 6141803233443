import React, { useState, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { CSSTransition } from 'react-transition-group';
import { Form, Field } from 'components/Form';
import { InView } from 'react-intersection-observer';
import { millisToMinutesAndSeconds } from '../../../../utils';
import Pagination from '../ResultsTable/Pagination/Pagination';
import Randomizer from '../Randomizer/Randomizer';
import Sorter from '../ResultsTable/Sorter/Sorter';

import Search from '../../../../../asset/resource/Search.png';

import '../ResultsTable/ResultsTable.scss';

const parserUsers = (users) =>
  users &&
  users.map((user, index) => ({
    id: user.id,
    rank: index + 1,
    nickname: user.nickname,
    duration: user.duration,
  }));

export const StaticResultsTable = ({ staticData }) => {
  let PageSize = 10;
  const [start, setStart] = useState(false);
  const resultList = parserUsers(staticData);
  const [sortedList, setSortedList] = useState(false);
  const [search, setSearch] = useState('');
  const [isError, setIsError] = useState(false);
  const [filteredList, setFilteredList] = useState(null);
  const [order, setOrder] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return !isEmpty(sortedList || resultList) && (sortedList || resultList).slice(firstPageIndex, lastPageIndex);
  }, [currentPage, PageSize, resultList, sortedList]);

  const onChangeHandler = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const submitFormSearch = () => {
    const list = resultList.filter((item) => item.nickname.toLowerCase().includes(search.toLowerCase()));

    if (list) {
      setFilteredList(list);
      setIsError(false);
    }
    if (isEmpty(list)) setIsError(true);
  };

  const resetForm = () => {
    setFilteredList('');
    setSearch('');
    setIsError(false);
  };

  const sortResults = () => {
    let res = resultList.sort((a, b) => (order === 'up' ? a.duration - b.duration : b.duration - a.duration));
    setSortedList(res);
  };

  const handlerSort = (order) => {
    setOrder(order);
    sortResults();
  };

  return (
    <div className="results-table">
      <div className="results-table__search">
        <Form name="registration" noValidate>
          <div className="form-box__row">
            <div className="form-box__col">
              <Field
                onChange={onChangeHandler}
                name="nickname"
                defaultValue={search}
                placeholder="Find your nickname..."
                maxLength={100}
                onKeyPress={(event) => event.key === 'Enter' && submitFormSearch()}
                error={isError && 'Nickname was not found'}
              />
              {filteredList ? (
                <button type="button" onClick={resetForm} className="results-table__form-button">
                  &#9587;
                </button>
              ) : (
                <button type="button" onClick={submitFormSearch} className="results-table__form-button">
                  <img width="100%" src={Search} className="search" alt="search" />
                </button>
              )}
            </div>
          </div>
        </Form>
      </div>
      <div className="results-table__results">
        <div className="results-table__list-title">
          <span className="results-table__list-rank">NUM</span>
          <span className="results-table__list-nickname">Nickname</span>
          <span className="results-table__list-time">
            Duration time <Sorter handler={handlerSort} active={order} />
          </span>
        </div>
        <ul className="results-table__list">
          <InView onChange={(inView) => (inView ? setStart(true) : setStart(false))}>
            {isEmpty(filteredList)
              ? currentTableData &&
                currentTableData.map(({ id, rank, nickname, duration }, i) => (
                  <CSSTransition key={id} in={start} timeout={100 * i} classNames="results-table__list-item">
                    <li className="results-table__list-item">
                      <span className="results-table__list-rank">{rank}</span>
                      <span className="results-table__list-nickname">{nickname}</span>
                      <span className="results-table__list-time">
                        {millisToMinutesAndSeconds(Number(duration))} min
                      </span>
                    </li>
                  </CSSTransition>
                ))
              : filteredList.map(({ id, rank, nickname, duration }, k) => (
                  <CSSTransition key={id} timeout={100} in={start} classNames="results-table__list-item">
                    <li className="results-table__list-item">
                      <span className="results-table__list-rank">{rank}</span>
                      <span className="results-table__list-nickname">{nickname}</span>
                      <span className="results-table__list-time">
                        {millisToMinutesAndSeconds(Number(duration))} min
                      </span>
                    </li>
                  </CSSTransition>
                ))}
          </InView>
        </ul>
      </div>
      {!filteredList && (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={resultList.length}
          pageSize={PageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
    </div>
  );
};

export default StaticResultsTable;
